import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import Header from './Header';
import { Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, CircularProgress, Select, FormControl, InputLabel, Snackbar } from '@mui/material';
import AudioRecorder, { generateAudioTimestamp, triggerUploadCompletion } from './Audiorecorder';
import PromptButtons from './Promptbuttons';
import Session from './Session';
import SessionGenerators from './Sessiongenerators';
import './App.css';

type ApiCallFunction = () => Promise<void>;

interface DecodedToken {
  username: string;
  exp: number;
}
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Access-Token aus dem Speicher abrufen
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Header setzen
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




function App() {
  const [selectedAudio, setSelectedAudio] = useState('');
  const [transcriptModel, setTranscriptModel] = useState('');
  const [transcriptContent, setTranscriptContent] = useState('');
  const [myprompt, setPrompt] = useState('');
  const [llmResponse, setLlmResponse] = useState('');
  const [audioFiles, setAudioFiles] = useState([]);
  const [transcriptFiles, setTranscriptFiles] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTranscriptDialogOpen, setIsTranscriptDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogLlmOpen, setDialogLlmOpen] = useState(false); // Dialog-Status
  const [dialogIonosOpen, setDialogIonosOpen] = useState(false);
  const [isAudioDialogOpen, setIsAudioDialogOpen] = useState(false);

  const [modelLlm, setModelLlm] = useState(''); // Parameter für das Modell für local
  const [selectedLlm, setSelectedLlm] = useState<string>(''); // Initialwert leer

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [audioName, setAudioName] = useState<string>('');


  const closeLoadingDialog = () => setIsLoading(false);
  const [user, setUser] = useState<string | null>(null);

  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);
const [username, setUsername] = useState('');
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');

  // Für die Authentifizierung

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded: DecodedToken = jwtDecode<DecodedToken>(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decoded.exp > currentTime) {
        setUser(decoded.username); // Benutzer setzen, wenn Token gültig ist
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        alert('Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.');
      }
    }
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {

      const token = localStorage.getItem('token');

      if (token) {
        const decoded: DecodedToken = jwtDecode<DecodedToken>(token);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decoded.exp < currentTime) {
          logout(); // Sitzung beenden, wenn Token abläuft
          alert('Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.');
        }
      }


    }, 6000); // Überprüfung alle 6 Sekunden

    return () => clearInterval(interval); // Aufräumen beim Verlassen der Komponente
  }, []);

  useEffect(() => {
    const channel = new BroadcastChannel('auth_channel');

    // Nachrichten empfangen
    channel.onmessage = (event) => {
      if (event.data === 'logout') {
        localStorage.removeItem('token'); // Token entfernen
        localStorage.removeItem('refreshToken'); // Falls gespeichert
        alert('Ihre Sitzung wurde in einem anderen Tab beendet.');
        setUser(null); // Benutzer zurücksetzen
      }
    };

    return () => {
      channel.close(); // Kanal beim Verlassen schließen
    };
  }, []);

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      try {
        const response = await axios.post('/auth/api/token/refresh/', {
          refresh: refreshToken,
        });
        const newAccessToken = response.data.access;
        localStorage.setItem('token', newAccessToken);
      } catch (error) {
        console.error('Token-Erneuerung fehlgeschlagen:', error);
        logout(); // Benutzer abmelden, wenn die Erneuerung fehlschlägt
      }
    }
  };

  // Registrierung eines neuen Benutzers
  const register = async () => {
    const username = prompt('Enter a username:');
    const email = prompt('Enter your email:');
    const password = prompt('Enter a password:');

    if (username && email && password) {
      try {
        await axios.post('/auth/api/register/', {
          username,
          email,
          password,
        });
        alert('Registration successful! Please click activation link in email to activate your account.');
      } catch (error) {
        console.error('Registration failed:', error);
        alert('Registration failed. Please try again.');
      }
    } else {
      alert('Please provide username, email, and password.');
    }
  };
  const handleLogin = async () => {
    if (username && password) {
      try {
        const response = await axios.post('/auth/api/token/', {
          username,
          password,
        });
        const accessToken = response.data.access;
        const refreshToken = response.data.refresh;
        const decoded: DecodedToken = jwtDecode<DecodedToken>(accessToken); // JWT dekodieren
        const currentTime = Math.floor(Date.now() / 1000);

        if (decoded.exp < currentTime) {
          alert('Token ist abgelaufen. Bitte erneut einloggen.');
          return;
        }

        setUser(decoded.username); // Benutzername setzen
        localStorage.setItem('token', accessToken); // Token im Local Storage speichern
        localStorage.setItem('refreshToken', refreshToken); // Refresh-Token speichern
        setIsLoginDialogOpen(false)
      } catch (error) {
        console.error('Login failed:', error);
        alert('Login failed. Please check your credentials.');
      }
    }
  };
  
  const handleRegister = async () => {
    if (username && email && password) {
      try {
        await axios.post('/auth/api/register/', {
          username,
          email,
          password,
        });
        alert('Registration successful! Please click activation link in email to activate your account.');
        setIsRegisterDialogOpen(false)
      } catch (error) {
        console.error('Registration failed:', error);
        alert('Registration failed. Please try again.');
      }
    } else {
      alert('Please provide username, email, and password.');
    }
  };

  // Einloggen eines Benutzers
  
  // Ausloggen eines Benutzers
  const logout = () => {
    setUser(null); // Benutzer zurücksetzen
    localStorage.removeItem('token'); // Token aus Local Storage entfernen
    localStorage.removeItem('refreshToken'); // Falls gespeichert

    // Nachricht über BroadcastChannel senden
    const channel = new BroadcastChannel('auth_channel');
    channel.postMessage('logout');
    channel.close();

    alert('Logged out successfully.');
  };







  // Typ für apiCall hinzugefügt
  const handleRequest = async (apiCall: ApiCallFunction) => {
    setIsLoading(true);
    try {
      await apiCall();
    } catch (error) {
      console.error('Error during API call:', error);
    } finally {
      setIsLoading(false);
    }
  };


  // Handle file upload
  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await refreshAccessToken()
    const file = event.target.files?.[0]; // Optional chaining, falls files null ist
    if (!file) {
      alert('Keine Audiodatei ausgewählt.');
      return;
    }
    // Extrahiere ursprünglichen Namen und Endung
    const originalName = triggerUploadCompletion.name.substring(0, file.name.lastIndexOf('.')) || file.name; // Ohne Endung
    const extension = file.name.substring(file.name.lastIndexOf('.'));
    const timestampFilename = generateAudioTimestamp(extension); // Nur Zeitstempel und Endung

    // Setze den audioName für die Verarbeitung
    //setAudioName(originalName);
    //setGeneratedFilename(timestampFilename);

    const formData = new FormData();
    formData.append('file', file, timestampFilename); // Neuer Name wird hier verwendet

    try {
      await axios.post('/api/apps/upload-audio/', formData);
      alert(`Datei "${file}" erfolgreich hochgeladen und in das .wav-Format konvertiert.`);
      await triggerUploadCompletion(timestampFilename, originalName, 0);
    } catch (error) {
      console.error('Fehler beim automatischen Abschluss der Verarbeitung:', error);
      alert('Fehler beim automatischen Abschluss der Verarbeitung. Bitte versuchen Sie es manuell.');
    }

  };
  // Download Audio File

  const handleAudioDownload = async () => {
    if (!selectedAudio) {
      alert('Keine Audiodatei ausgewählt.');
      return;
    }

    try {
      const response = await axios.get(`/api/apps/audio-download/${selectedAudio}/`, {
        responseType: 'blob',
      });
      const url = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = selectedAudio;
      link.click();
      URL.revokeObjectURL(url);
      alert('Download erfolgreich.');
    } catch (error) {
      console.error('Fehler beim Herunterladen der Audiodatei:', error);
      alert('Fehler beim Download. Bitte versuchen Sie es erneut.');
    }
  };





  // Load selected transcript content
  const loadSelectedTranscript = async (filename: string) => {
    refreshAccessToken()
    try {
      const response = await axios.get(`/api/apps/get-transcript/${filename}`);
      setTranscriptContent(response.data.content);
      alert('Transkript erfolgreich geladen.');
    } catch (error) {
      console.error('Error loading transcript:', error);
    }
  };

  // Transcribe selected audio file
  const handleTranscriptionWhisper = async () => {
    setTranscriptContent('');
    await handleRequest(async () => {
      const response = await axios.post('/api/apps/transcribe-audio-whisper/', { filename: selectedAudio });
      setTranscriptContent(response.data.content);
      if (response.data.current_script) {
        setTranscriptModel(response.data.current_script); // Setzt das aktuelle Skript
      }
      alert('Transkription erfolgreich.');
    });
  };
  const handleTranscriptionLocalSmall = async () => {
    setTranscriptContent('');
    await handleRequest(async () => {
      const response = await axios.post('/api/apps/transcribe-audio-local-small', { filename: selectedAudio });
      setTranscriptContent(response.data.content);
      if (response.data.current_script) {
        setTranscriptModel(response.data.current_script); // Setzt das aktuelle Skript
      }
      alert('Transkription erfolgreich.');
    });
  };

  const handleTranscriptionLocalLarge = async () => {
    setTranscriptContent('');
    await handleRequest(async () => {
      const response = await axios.post('/api/apps/transcribe-audio-local-large/', { filename: selectedAudio });
      setTranscriptContent(response.data.content);
      if (response.data.current_script) {
        setTranscriptModel(response.data.current_script); // Setzt das aktuelle Skript
      }
      alert('Transkription erfolgreich.');
    });
  };

  // Send transcript and prompt to LLM
  const handleLlmRequestChatgpt = async () => {
    setLlmResponse('');
    setSelectedLlm('ChatGPT'); // Setzt ChatGPT als ausgewählte LLM
    await handleRequest(async () => {
      const response = await axios.post('/api/apps/send-to-llm-chatgpt/', {
        transcript: transcriptContent,
        prompt: myprompt,
      });
      setLlmResponse(response.data.response);
    });
  };

  const handleLlmRequestIonos = async () => {
    setLlmResponse('');
    setSelectedLlm('ChatGPT');
    setSelectedLlm(modelLlm);  // Setzt ChatGPT als ausgewählte LLM
    setDialogIonosOpen(false);
    await handleRequest(async () => {
      const response = await axios.post('/api/apps/send-to-llm-ionos/', {
        transcript: transcriptContent,
        prompt: myprompt,
        model: modelLlm,
      });
      setLlmResponse(response.data.response);
    });
  };

  const handleLlmRequestLocal = async () => {
    setLlmResponse('');
    setSelectedLlm(modelLlm); // Setzt das ausgewählte Modell als ausgewählte LLM
    setDialogLlmOpen(false); // Schließe den Dialog nach Bestätigung
    await handleRequest(async () => {
      const response = await axios.post('/api/apps/send-to-llm-local/', {
        transcript: transcriptContent,
        prompt: myprompt,
        model: modelLlm, // Ausgewähltes Modell wird an die API gesendet
      });
      setLlmResponse(response.data.response);
    });
  };

  // Download combined result
  const handleDownload = () => {
    const currentDateTime = new Date().toLocaleString('de-DE', {
      dateStyle: 'long',
      timeStyle: 'short',
    }).replace(/[.,:]/g, '').replace(/ /g, '_'); // Formatierung des Datums und der Uhrzeit
    const fileName = `KVITA_${currentDateTime}.txt`;
    const blob = new Blob(
      [`Datum und Uhrzeit: ${currentDateTime}\n` +
        `Transkriptions-Model: ${transcriptModel || 'Keines ausgewählt'}\n` +
        `LLM: ${selectedLlm || 'Keine ausgewählt'}\n\n` +
        `------------ Transcript ------------\n\n` +
        `${transcriptContent}\n\n` +
        `------------ Prompt ------------\n\n` +
        `${myprompt}\n\n` +
        `------------ LLM Response ------------\n\n` +
        `${llmResponse}`

      ],
      { type: 'text/plain' }
    );
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  };

  const styles: { [key: string]: React.CSSProperties } = {
    header: {
      backgroundColor: '#2A5941',
      color: '#FFFFFF',
      padding: '20px',
      textAlign: 'center',
      borderRadius: '10px',
    },
    container: {
      backgroundColor: '#F1F8E9',
      padding: '20px',
      minHeight: '100vh',
    },
    button: {
      backgroundColor: '#C5E1A5',
      color: '#2A5941',
      margin: '10px',
      border: '1px solid #2A5941',
      borderRadius: '8px',
      padding: '10px 20px',
    },
    textField: {
      border: '1px solid #C5E1A5',
      borderRadius: '5px',
      marginTop: '10px',
      backgroundColor: '#FFFFFF',
    },
    dialogContent: {
      padding: '20px',
    },
  };













  return (

    <div style={{ padding: '20px' }}>
<Header
  user={user}
  onRegister={() => {
    console.log("Register button clicked");
    setIsRegisterDialogOpen(true);
  }}
  onLogin={() => {
    console.log("Login button clicked");
    setIsLoginDialogOpen(true);
    console.log('isLoginDialogOpen:', isLoginDialogOpen);
  }}
  onLogout={logout}
/>      {user ? (
        <div style={{ padding: '10px' }}>
          <Typography variant="h4">KVITA (V2) - Intermediate : Audio-Transkripte und KI - Analysen</Typography>


          <div style={{ display: 'flex', padding: '10px' }}>

            <div style={{ flex: '3', padding: '10px', borderRight: '1px solid #ccc' }}>
              <Session
                setSelectedAudio={setSelectedAudio}
                setTranscriptContent={setTranscriptContent}
                setTranscriptModel={setTranscriptModel}
              />
            </div>
            
            <div style={{ flex: '8', padding: '20px' }}>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>
                  Neue Sitzung erstellen
                </Typography>
                
                <SessionGenerators 
            onFileUpload={handleUpload} 

            />
              

              <div style={{ marginTop: '20px' }}>
                {/* Neue Sitzung erstellen */}

                
                
              
              </div>
              <div style={{ marginTop: '20px' }}>

                <Typography variant="h6" style={{ marginBottom: '10px' }}>
                  Transkription in Session erstellen
                </Typography>
                {/* Transcribe Button Whisper*/}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTranscriptionWhisper}
                  disabled={!selectedAudio}
                  style={{ marginTop: '20px', marginRight: '10px' }}
                >
                  Audiodatei mit Whisper transkribieren
                </Button>

                {/* Transcribe Button Local Small */}
                
              </div>


              





              {/* Transcript Text Area */}
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Aktuelle Audiodatei: {selectedAudio || 'Keine Audio ausgewählt'}
                {selectedAudio && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAudioDownload}
                    style={{ marginLeft: '10px' }}
                  >
                    Audio herunterladen
                  </Button>
                )}
              </Typography>
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Transkritions-Modell: {transcriptModel || 'Kein Skript ausgewählt'}
              </Typography>
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Transkript (Editierbar)
              </Typography>
              <TextField
                multiline
                rows={10}
                fullWidth
                variant="outlined"
                value={transcriptContent}
                onChange={(e) => setTranscriptContent(e.target.value)}
                style={{ marginTop: '10px' }}
              />

              {/* Prompt Text Field */}
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Prompt für LLM
              </Typography>
              <PromptButtons
                setPrompt={setPrompt}
                myprompt={myprompt}
              />
              
              <TextField
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                value={myprompt}
                
                onChange={(e) => setPrompt(e.target.value)}
                style={{ marginTop: '10px' }}
              />

            

              {/* Send to LLM Button IONOS*/}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setDialogIonosOpen(true)} // Öffnet den IONOS-Dialog
                style={{ marginTop: '20px', marginRight: '10px' }}
              >
                An LLM senden <br /> IONOS
              </Button>

              {/* LLM Response */}
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Aktuelle LLM: {selectedLlm || 'Keine LLM ausgewählt'}
              </Typography>
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                LLM Antwort
              </Typography>
              <TextField
                multiline
                rows={10}
                fullWidth
                variant="outlined"
                value={llmResponse}
                style={{ marginTop: '10px' }}

              />

              {/* Download Result */}
              <Button variant="contained" color="success" onClick={handleDownload} style={{ marginTop: '20px' }}>
                Ergebnis herunterladen
              </Button>
              <Dialog open={dialogIonosOpen} onClose={() => setDialogIonosOpen(false)}>
                <DialogTitle>Modell auswählen (IONOS)</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth style={{ marginBottom: '20px' }}>
                    <InputLabel id="ionos-model-select-label">Modell</InputLabel>
                    <Select
                      labelId="ionos-model-select-label"
                      value={modelLlm}
                      onChange={(e) => setModelLlm(e.target.value)}
                    >
                      <MenuItem value="meta-llama/Meta-Llama-3.1-70B-Instruct">Meta-Llama-3.1-70B-Instruct</MenuItem>
                      <MenuItem value="mistralai/Mistral-7B-Instruct-v0.3">Mistral-7B-Instruct-v0.3</MenuItem>
                      <MenuItem value="meta-llama/Meta-Llama-3.1-8B-Instruct">Meta-Llama-3.1-8B-Instruct</MenuItem>
                      <MenuItem value="mistralai/Mixtral-8x7B-Instruct-v0.1">Mixtral-8x7B-Instruct-v0.1</MenuItem>





                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDialogIonosOpen(false)} color="primary">
                    Abbrechen
                  </Button>
                  <Button onClick={handleLlmRequestIonos} color="secondary">
                    Senden
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={dialogLlmOpen} onClose={() => setDialogLlmOpen(false)}>
                <DialogTitle>Modell auswählen</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth style={{ marginBottom: '20px' }}>
                    <InputLabel id="model-select-label">Modell</InputLabel>
                    <Select
                      labelId="model-select-label"
                      value={modelLlm}
                      onChange={(e) => setModelLlm(e.target.value)}
                    >
                      <MenuItem value="llama3.1">llama3.1</MenuItem>
                      <MenuItem value="gemma2">gemma2</MenuItem>
                      <MenuItem value="mistral">mistral</MenuItem>
                      <MenuItem value="phi3:medium">phi3:medium</MenuItem>
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDialogLlmOpen(false)} color="primary">
                    Abbrechen
                  </Button>
                  <Button onClick={handleLlmRequestLocal} color="secondary">
                    Senden
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={isLoading} onClose={closeLoadingDialog}>
                <DialogContent>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress style={{ marginRight: '10px' }} />
                    <Typography>Bitte warten...</Typography>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeLoadingDialog}>Ausblenden <br />  (bricht nicht ab!)</Button>
                </DialogActions>
              </Dialog>
             
              <AudioRecorder
                isAudioDialogOpen={isAudioDialogOpen}
                setIsAudioDialogOpen={setIsAudioDialogOpen}
              />


            </div></div></div>) : (
        <p>Bitte loggen Sie sich ein, um KVITA nutzen zu können.</p>

      )} <Dialog open={isLoginDialogOpen} onClose={() => setIsLoginDialogOpen(false)}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <TextField
          label="Benutzername"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Passwort"
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsLoginDialogOpen(false)}>Abbrechen</Button>
        <Button onClick={handleLogin}>Login</Button>
      </DialogActions>
    </Dialog>
    
    <Dialog open={isRegisterDialogOpen} onClose={() => setIsRegisterDialogOpen(false)}>
      <DialogTitle>Registrieren</DialogTitle>
      <DialogContent>
        <TextField
          label="Benutzername"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          margin="normal"
        />
        <TextField
          label="E-Mail"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Passwort"
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsRegisterDialogOpen(false)}>Abbrechen</Button>
        <Button onClick={handleRegister}>Registrieren</Button>
      </DialogActions>
    </Dialog>
    </div>

  );
}

export default App;