import React, { useState, useEffect } from 'react';
import axios from 'axios';

/////<span>{user ? `Welcome, ${user}` : 'Please log in or register'}</span>



interface UserSettings {
  email: string;
  username: string;
  theme: string;
  notifications_enabled: boolean;
  title: string;
  language: string;
}

interface HeaderProps {
  user: string | null;
  onRegister: () => void;
  onLogin: () => void;
  onLogout: () => void;
}

  const Header: React.FC<HeaderProps> = ({ user, onRegister, onLogin, onLogout }) => {
  const [settings, setSettings] = useState<UserSettings | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (user) {
      axios.get('/auth/user/get-user-settings') // Endpunkt anpassen
        .then((response) => {
          setSettings(response.data); // Die Daten aus der Axios-Antwort setzen
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Benutzerdaten:', error);
        });
    }
  }, [user]);

  return (
    <div style={{ background: '#f5f5f5', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', gap: '15px' }}>
        {/* Firmenlogo */}
        <img
          src="/logo.png"
          alt="Firmenlogo"
          style={{ height: '60px' }}
        />
        {user && (
          <div
            style={{
              display: 'flex',
              gap: '40px', // Abstand zwischen den Links
              fontSize: '18px', // Größere Schrift
              fontWeight: 'bold',
              alignItems: 'center', // Stellt sicher, dass auch die Links zentriert sind
              paddingLeft: '30px', // Abstand zum Logo
            }}
          >

            <a href="/record" style={{ textDecoration: 'none', color: '#007bff' }}>-- RECORD --</a>
            <a href="/sessions" style={{ textDecoration: 'none', color: '#007bff' }}>Sessions</a>
            <a href="/templates" style={{ textDecoration: 'none', color: '#007bff' }}>Templates</a>
            <a href="/settings" style={{ textDecoration: 'none', color: '#007bff' }}>Einstellungen</a>
          </div>
        )}
      </div>      <div style={{ position: 'relative' }}>
        {user ? (
          <>
            <div
              onClick={() => setShowDropdown(!showDropdown)}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                background: '#ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <span style={{ fontSize: '35px', color: '#fff' }}>👤</span>
            </div>
            {showDropdown && settings && (
              <div
                style={{
                  position: 'absolute',
                  top: '70px',
                  right: '0',
                  background: '#fff',
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                  padding: '35px',
                  width: '350px',
                  zIndex: 9999, // Damit das Dropdown-Menü über anderen Elementen liegt
                }}
              >
                {/* Schließen-Button oben rechts */}
                <button
                  onClick={() => setShowDropdown(false)}
                  style={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    background: 'none',
                    border: 'none',
                    fontSize: '25px',
                    cursor: 'pointer',
                  }}
                >
                  X
                </button>
                <p style={{ margin: '5px 0', textAlign: 'center' }}>{settings.email}</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '110px',
                    height: '110px',
                    borderRadius: '50%',
                    background: '#ccc',
                    margin: '20px auto',
                  }}
                >
                  <p style={{ fontSize: '75px', color: '#fff', textAlign: 'center' }}>👤</p>
                </div>
                <p style={{ margin: 0, fontWeight: 'bold', textAlign: 'center' }}>{settings.username}</p>
                <hr />
                <p style={{ margin: '5px 0' }}>Title: {settings.title}</p>
                <p style={{ margin: '5px 0' }}>Notifications: {settings.notifications_enabled ? 'Enabled' : 'Disabled'}</p>
                <p style={{ margin: '5px 0' }}>Theme: {settings.theme}</p>
                <p style={{ margin: '5px 0' }}>Language: {settings.language}</p>


                <button
                  onClick={() => console.log('Einstellungen bearbeiten')}
                  style={{
                    marginTop: '10px',
                    width: '100%',
                    padding: '15px',
                    background: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '15px', 
                  }}
                >
                  Einstellungen ändern
                </button>
                <button
                  onClick={() => {
                    setShowDropdown(false);
                    onLogout();
                  }}
                  style={{
                    padding: '12px',
                    width: '100%',
                    background: '#ff4d4f',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginTop: '12px',
                    fontSize: '15px', 

                  }}
                >
                  Logout
                </button>
                <hr />
                <a href="/privacy" style={{ display: 'block', margin: '5px 0', color: '#007bff' }}>
                  Datenschutzerklärung
                </a>
                <a href="/terms" style={{ display: 'block', margin: '5px 0', color: '#007bff' }}>
                  Nutzungsbedingungen
                </a>
              </div>
            )}
          </>
        ) : (
          <>
            <button onClick={onRegister} style={{ marginRight: '10px' }}>
              Register
            </button>
            <button onClick={onLogin}>Login</button>
          </>
        )}
      </div>
    </div>
  );
};
export default Header;