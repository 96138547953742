import React, { useEffect, useState } from 'react';
import axios from 'axios';
//import './PromptButtons.css';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface ButtonData {
    id: number;
    buttonname: string;
    content: string;
}

interface PromptButtonsProps {
    setPrompt: React.Dispatch<React.SetStateAction<string>>;
    myprompt: string;
}

const PromptButtons: React.FC<PromptButtonsProps> = ({ setPrompt, myprompt }) => {
    const [buttons, setButtons] = useState<ButtonData[]>([]);
    const [newButtonName, setNewButtonName] = useState('');
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

    // Buttons aus der API abrufen
    const fetchButtons = async () => {
        try {
            const response = await axios.get('/api/apps/fetch-prompt-buttons/');
            if (response.status === 200) {
                setButtons(response.data);
            } else {
                console.error('Fehler beim Abrufen der Buttons:', response.status);
            }
        } catch (error) {
            console.error('API-Fehler beim Abrufen der Buttons:', error);
        }
    };

    // Button hinzufügen
    const addButton = async () => {
        if (!newButtonName.trim()) {
            alert('Bitte einen gültigen Namen eingeben.');
            return;
        }

        try {
            const response = await axios.post('/api/apps/add-prompt-button/', {
                buttonname: newButtonName,
                content: myprompt,
            });
            if (response.status === 201) {
                setButtons([...buttons, response.data]);
                setNewButtonName('');
                setIsAddDialogOpen(false);
            } else {
                console.error('Fehler beim Hinzufügen des Buttons:', response.status);
            }
        } catch (error) {
            console.error('API-Fehler beim Hinzufügen des Buttons:', error);
        }
    };

    // Button löschen
    const deleteButton = async (button_id: number) => {
        if (!window.confirm('Möchten Sie den Button wirklich löschen?')) return;

        try {
            const response = await axios.delete(`/api/apps/delete-prompt-button/${button_id}/`);
            if (response.status === 200) {
                setButtons(buttons.filter((button) => button.id !== button_id));
            } else {
                console.error('Fehler beim Löschen des Buttons:', response.status);
            }
        } catch (error) {
            console.error('API-Fehler beim Löschen des Buttons:', error);
        }
    };

    useEffect(() => {
        fetchButtons(); // Initiales Laden der Buttons
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', overflowX: 'auto', padding: '10px', backgroundColor: '#f9f9f9' }}>
            {buttons.map((button) => (
                <div
                    key={button.id}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#e3f2fd',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '200px',
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            textTransform: 'none',
                            fontSize: '0.8rem',
                            flexGrow: 1,
                            whiteSpace: 'nowrap', // Text in einer Zeile halten
                            overflow: 'hidden',  // Überlauf ausblenden
                            textOverflow: 'ellipsis', // "..." hinzufügen, wenn Text abgeschnitten wird
                            maxWidth: '170px',  // Maximale Breite des Textes
                        }}
                        onClick={() => setPrompt(button.content)}
                    >
                        {button.buttonname}
                    </Button>
                    <IconButton size="small" color="secondary" onClick={() => deleteButton(button.id)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ))}
            <IconButton
                size="medium"
                color="primary"
                onClick={() => setIsAddDialogOpen(true)}
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#81c784',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
            >
                <AddIcon />
            </IconButton>
            <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
                <DialogTitle>Neuen Button hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Button Name"
                        value={newButtonName}
                        onChange={(e) => setNewButtonName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAddDialogOpen(false)} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={addButton} color="primary">
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PromptButtons;