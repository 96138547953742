import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Session.css';
import { Typography } from '@mui/material';


interface SessionProps {
    setSelectedAudio: React.Dispatch<React.SetStateAction<string>>;
    setTranscriptContent: React.Dispatch<React.SetStateAction<string>>;
    setTranscriptModel: React.Dispatch<React.SetStateAction<string>>;
}


const Session: React.FC<SessionProps> = ({ setSelectedAudio, setTranscriptContent, setTranscriptModel }) => {
    // Zustand für Sitzungen
    const [sessions, setSessions] = useState<any[]>([]);
    const [selectedId, setSelectedId] = useState<number | null>(null); // Markierter Datensatz
    const [dataField, setDataField] = useState<any>(null); // JSON-Daten



    // Funktion zum Abrufen der Sitzungsdaten
    // !!! Das ist bei vielen daten und vielen anfragen recht ineffizient. Sollte man bei bedarf ändern
    const fetchSessions = async () => {
        try {
            const response = await axios.get('/api/apps/fetch-sessions/');
            if (response.status === 200) {
                // Daten erfolgreich abgerufen
                const data = response.data;
                const sortedData = data.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());

                setSessions(sortedData);
                console.log('Sitzungsdaten:', data);

                return data; // Gibt die Daten zurück
            } else {
                console.error('Fehler beim Abrufen der Daten:', response.status);
            }
        } catch (error) {
            console.error('Fehler bei der API-Anfrage:', error);
        }
    };

    const fetchSessionDataById = async (id: number) => {
        try {
            const response = await axios.get(`/api/apps/fetch-sessions/`);
            if (response.status === 200) {
                const sessionData = response.data.find((session: any) => session.id === id);
                console.log('Einzelne Sitzung:', sessionData);

                // Zustände setzen
                setSelectedAudio(sessionData.audio_file_name || '');
                setDataField(sessionData.data || null);
                const transcriptText = sessionData.data?.Transcript?.Text || 'Kein Transkript vorhanden';
                setTranscriptContent(transcriptText);
                const transcriptModel = sessionData.data?.Transcript?.Model || 'Unbekanntes Modell';
                setTranscriptModel(transcriptModel);

            } else {
                console.error('Fehler beim Abrufen des Datensatzes:', response.status);
            }
        } catch (error) {
            console.error('Fehler bei der API-Anfrage:', error);
        }
    };

    // useEffect mit Intervall für regelmäßige Abfragen
    useEffect(() => {
        console.log('useEffect gestartet');
        fetchSessions(); // Initialer Datenabruf
        const interval = setInterval(() => {
            fetchSessions();
        }, 5000); // Alle 5 Sekunden abfragen

        return () => clearInterval(interval); // Aufräumen beim Verlassen der Komponente
    }, []);




    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <Typography variant="h6" style={{ marginBottom: '10px' }}>
                Sitzungsliste (Audios)</Typography >
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {sessions.map((session, index) => (
                    <li
                        key={index}
                        data-id={session.id}
                        onClick={() => {
                            setSelectedId(session.id);
                            fetchSessionDataById(session.id);
                        }}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '15px',
                            marginBottom: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            backgroundColor: selectedId === session.id ? 'lightblue' : '#fff',
                            cursor: 'pointer',
                        }}
                    >
                        <div>
                            <strong style={{ fontSize: '1rem' }}>{session.title}</strong>
                            <div style={{ color: '#666', fontSize: '0.9rem' }}>{session.date}</div>
                            <div style={{ color: '#666', fontSize: '0.9rem' }}>{session.duration}</div>

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <span
                                style={{
                                    color: 'green',
                                    backgroundColor: 'green',
                                    fontSize: '0.8rem',
                                    padding: '4px 8px',
                                    border: '1px solid green',
                                    borderRadius: '4px',
                                }}
                            >
                                {session.status}
                            </span>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation(); // Verhindert Auslösen des onClick-Events des Listenelements
                                    if (window.confirm("Möchten Sie die Session wirklich unwiderruflich löschen?")) {
                                        axios
                                            .delete(`/api/apps/delete-session/${sessions[index].id}/`)
                                            .then((response) => {
                                                if (response.status === 200) {
                                                    const newSessions = sessions.filter((_, i) => i !== index);
                                                    setSessions(newSessions);
                                                    setSelectedId(null);
                                                    setSelectedAudio('');
                                                } else {
                                                    alert("Fehler beim Löschen der Session.");
                                                }
                                            })
                                            .catch((error) => {
                                                console.error("Fehler beim Löschen:", error);
                                                alert("Fehler beim Löschen der Session.");
                                            });
                                    }
                                }}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: '1.2rem',
                                    color: '#888',
                                }}
                            >
                                🗑️
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Session;