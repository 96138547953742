import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import AudioRecorderTab from './Audiorecordertab';


interface AudioUploadButtonProps {
    onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }

const SessionGenerators: React.FC<AudioUploadButtonProps> = ({onFileUpload}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs = [
    {
      title: "Neue Sitzung",
      content: (
        <div>
          <AudioRecorderTab />
        </div>
      ),
    },
    {
      title: "Upload",
      content: (
        <div>
          <Typography variant="h6">Bitte Datei auswählen:</Typography>
         
            <br />
            {/* Upload Audio Button */}
                            <Button variant="contained" component="label" style={{ padding: "10px", backgroundColor: "#2A5941", color: "#FFFFFF", border: "none", borderRadius: "8px" }}>
                              Audiodatei auswählen
                              <input type="file" hidden onChange={onFileUpload}/>
                            </Button>
           
            
          
        </div>
      ),
    },
    {
      title: "Diktieren",
      content: (
        <div>
          <Typography variant="h6">Noch nicht implementiert</Typography>
        </div>
      ),
    },
  ];

  const styles = {
    tabContainer: {
      backgroundColor: "#F1F8E9",
      padding: "10px",
      borderRadius: "8px",
      border: "1px solid #C5E1A5",
      maxWidth: "600px",
      
    },
    tabs: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
    },
    tabButton: (isActive: boolean) => ({
      flex: 1,
      backgroundColor: isActive ? "#2A5941" : "#C5E1A5",
      color: isActive ? "#FFFFFF" : "#2A5941",
      padding: "10px",
      margin: "0 5px",
      borderRadius: "8px",
      border: "none",
      cursor: "pointer",
      
    }),
    tabContent: {
      padding: "20px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #C5E1A5",
      borderRadius: "8px",
      minHeight: "200px",
    },
  };

  return (
    <Box style={styles.tabContainer}>
      <Box style={styles.tabs}>
        {tabs.map((tab, index) => (
          <Button
            key={index}
            onClick={() => setActiveTab(index)}
            style={styles.tabButton(activeTab === index)}
          >
            {tab.title}
          </Button>
        ))}
      </Box>
      <Box style={styles.tabContent}>{tabs[activeTab].content}</Box>
    </Box>
  );
};

export default SessionGenerators;